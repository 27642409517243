import axios, { AxiosInstance } from "axios";
import { BUILD_API_ENDPOINT } from "constants/configs";
import { IApiClient } from "services/api-client/ApiClient.types";
import merge from "lodash/merge";
import trim from "lodash/trim";

const LOCAL_STORAGE_LEY_BUILD_ADMIN_ACCESS_TOKEN = "BUILD_ADMIN_ACCESS_TOKEN";

export class ApiClient implements IApiClient {
  getAuthToken() {
    return trim(localStorage.getItem(LOCAL_STORAGE_LEY_BUILD_ADMIN_ACCESS_TOKEN) || "");
  }

  setAuthToken(token: string) {
    localStorage.setItem(LOCAL_STORAGE_LEY_BUILD_ADMIN_ACCESS_TOKEN, trim(token));
  }

  get adminApi(): AxiosInstance {
    const instance = axios.create({
      baseURL: `${BUILD_API_ENDPOINT}`,
    });

    instance.interceptors.request.use(async (config) => {
      //const idToken = await this.cognitoService.getIdToken();
      const idToken = this.getAuthToken() ?? "";

      const newConfig = merge({}, config, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      return newConfig;
    });

    return instance;
  }
}

export const apiClient = new ApiClient();
