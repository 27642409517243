import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import styled, { css } from "styled-components/macro";
import { apiClient } from "services/api-client/ApiClient";
import { AuthenticationContext } from "./App";

type Props = {};

const Authentication: React.FC<Props> = (props) => {
  const [tokenString, setTokenString] = useState("");

  const updateInputValue = (evt: { target: { value: React.SetStateAction<string> } }) => {
    setTokenString(evt.target.value);
  };

  const { setAuthenticated } = React.useContext(AuthenticationContext);

  return (
    <StyledToolBar>
      <TextField placeholder="Token" value={tokenString} onChange={updateInputValue} />
      <Button
        variant={"contained"}
        color={"primary"}
        onClick={async () => {
          console.log(tokenString);
          apiClient.setAuthToken(tokenString);
          setAuthenticated(true);
        }}
      >
        Authenticate
      </Button>
    </StyledToolBar>
  );
};

const StyledToolBar = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: 16px;
    align-items: center;
  `
);

export default Authentication;
