import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import styled, { css } from "styled-components/macro";
import { apiClient } from "services/api-client/ApiClient";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

type Props = {};

type ISchema = { name: string };

const fetchJurisdictionFormId = async (jName: string, fName: string) => {
  const jurisdiction = await apiClient.adminApi.get(`/admin/jurisdictions`);
  const jid = jurisdiction.data.jurisdictions.find((j: { name: string }) => j.name === jName).id;
  const form = await apiClient.adminApi.get(`/admin/jurisdictions/${jid}/forms`);
  const fid = form.data.forms.find((f: { name: string }) => f.name === fName).id;
  return [jid, fid];
};

const FormioFormEditor: React.FC<Props> = (props) => {
  const formioIFrameRef = React.useRef<HTMLIFrameElement | null>(null);

  // The current jurisdiction and form
  const [jurisdictionId, setJurisdictionId] = useState<number>(0);
  const [formId, setFormId] = useState<number>(0);
  const [schemas, setSchemas] = useState<ISchema[]>([]);

  // Name of the currently selected formio schema
  const [formioName, setFormioName] = useState<string>("");

  React.useEffect(() => {
    const callback = (event: any) => {
      const data = event.data;
      if (!data || !data.action) {
        return;
      }

      if (data.action === "FormEditor/SaveSchema") {
        if (data.payload && data.payload.schema) {
          console.log("FormEditor/SaveSchema", event);
          apiClient.adminApi.patch(`/admin/jurisdictions/${jurisdictionId}/forms/${formId}/formioschema`, {
            name: formioName,
            schema: JSON.stringify(data.payload.schema),
          });
        }
      }
    };

    window.addEventListener("message", callback);

    return () => {
      window.removeEventListener("message", callback);
    };
  }, [jurisdictionId, formId, formioName]);

  React.useEffect(() => {
    fetchJurisdictionFormId("NZ", "building-consent").then(([jid, fid]) => {
      setJurisdictionId(jid);
      setFormId(fid);

      apiClient.adminApi.get(`/admin/jurisdictions/${jid}/forms/${fid}/formioschema`).then((schemaData) => {
        const loadedSchemas = schemaData.data.formSchemas;
        console.debug(loadedSchemas);
        setSchemas(loadedSchemas);
      });
    });
  }, []);

  const handleAutoCompleteChange = React.useCallback(
    async (evt: React.ChangeEvent<any>, value: ISchema | any, reason: string) => {
      if (reason === "select-option") {
        if (!value) {
          return;
        }

        const response = await apiClient.adminApi.get(
          `/admin/jurisdictions/${jurisdictionId}/forms/${formId}/formioschema/${value.name}`
        );
        console.log(response.data.schema);
        setFormioName(value.name);

        formioIFrameRef.current?.contentWindow?.postMessage(
          {
            action: "AdminUI/LoadSchema",
            payload: {
              schema: JSON.parse(response.data.schema),
            },
          },
          "*"
        );
      }

      // TODO:
    },
    [jurisdictionId, formId]
  );

  const iframeSrc = React.useMemo(() => {
    return "http://builddevformbuilder.objectivebuild.com/?v=" + Math.random();
  }, []);

  return (
    <StyledContainer>
      <StyledToolBar>
        <div>Schema</div>
        <Autocomplete<ISchema>
          clearOnBlur={false}
          clearOnEscape={false}
          options={schemas}
          getOptionLabel={(option: ISchema) => option.name}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          onChange={handleAutoCompleteChange}
        />

        <Button
          color={"primary"}
          onClick={() => {
            formioIFrameRef.current?.contentWindow?.postMessage(
              {
                action: "AdminUI/SaveSchema",
              },
              "*"
            );
          }}
        >
          Save Schema
        </Button>
      </StyledToolBar>
      <iframe title={"FormioFormEditor"} src={iframeSrc} width={"100%"} height={"800px"} ref={formioIFrameRef} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: 16px;
    flex-direction: column;
  `
);

const StyledToolBar = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: 16px;
    align-items: center;
  `
);

export default FormioFormEditor;
