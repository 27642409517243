// Proxy access to process.env to provide meaningful defaults

// Backend
export const BUILD_API_ENDPOINT: string = process.env.REACT_APP_BUILD_API_ENDPOINT || "";

// Env
export const NODE_ENV: string = process.env.NODE_ENV || "development";
export const IS_DEV_ENV: boolean = NODE_ENV === "development";
export const IS_TEST_ENV: boolean = NODE_ENV === "test";
export const IS_PRODUCTION_ENV: boolean = NODE_ENV === "production";

// AWS
export const AWS_COGNITO_REGION = process.env.REACT_APP_AWS_COGNITO_REGION || "";
export const AWS_USER_POOLS_ID = process.env.REACT_APP_AWS_USER_POOLS_ID || "";
export const AWS_USER_POOLS_WEB_CLIENT_ID = process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID || "";
export const AWS_COGNITO_USE_MOCK = process.env.REACT_APP_AWS_COGNITO_USE_MOCK === "true";
