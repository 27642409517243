// Color palette extracted from ODL and design language
// Refer: https://www.figma.com/file/TDq5O0gSOI9O6JQl2o61ZY/Objective-Keystone-Version-6?node-id=2%3A0
export const objectivePalette = {
  dark: {
    night: "#32373F",
    fiordland: "#525965",
    neutral: "#707070",
    neutral2: "#999999",
  },
  light: {
    allspice: "#D1D1D1",
    taro: "#EFEFEB",
    mid: "#EFEFEF",
    day: "#F5F5F5",
    daylight: "#F3F3F3",
    white: "#FFFFFF",
  },
  blue: {
    main: "#0266C8",
    light: "#E0F3FE",
  },
  red: {
    main: "#BD2030",
    light: "#F3D7DA",
    salmon: "#F3D7DA",
  },
  green: {
    main: "#2A7D2A",
    light: "#DFF6DF",
    dark: "#00893E",
  },
  yellow: {
    main: "#FECD71",
  },
  orange: {
    ripeCarrot: "#C63906",
    freshCarrot: "#E95829",
  },
  background: {
    freshMango: "#FEE094",
    objectiveYellowLight: "#FDEED3",
    freshCabbage: "#9BE1DC",
    objectiveCardamon: "#DAE3C1",
    freshBlueberry: "#DAD9FC",
    freshLime: "#A2EC9F",
    freshRaspberry: "#F7CAD7",
  },
  brandColors: {
    build: {
      primary: "#5CA3FF",
      web: "#5CA3FF",
    },
    perform: {
      primary: "#BD2030",
      web: "#E95829",
    },
    keystone: {
      primary: "#E95829",
      web: "#C63906",
    },
    trapeze: {
      primary: "#F3AD2E",
      web: "#F3AD2E",
    },
    connect: {
      primary: "#4AAC51",
      web: "#4AAC51",
    },
    ecm: {
      primary: "#007DC3",
      web: "#007DC3",
    },
    inform: {
      primary: "#9D3F97",
      web: "#9D3F97",
    },
    teams: {
      primary: "#5558AF",
      web: "#5558AF",
    },
    office: {
      primary: "#D83B00",
      web: "#D83B00",
    },
  },
};
