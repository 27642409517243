import { createMuiTheme } from "@material-ui/core";
import { objectivePalette } from "styles/objectivePalette";
import ObjectiveWOFF from "assets/fonts/Objective.woff";
import ObjectiveWOFF2 from "assets/fonts/Objective.woff2";
import NotoSansRegular from "assets/fonts/NotoSans-Regular.ttf";
import NotoSansMedium from "assets/fonts/NotoSans-Medium.ttf";
import NotoSansSemiBold from "assets/fonts/NotoSans-SemiBold.ttf";
import NotoSansBold from "assets/fonts/NotoSans-Bold.ttf";

// prettier-ignore
const standardFontFamily = [
  "Noto Sans",
  "sans-serif",
].join(",");

const standardTypography = {
  fontFamily: standardFontFamily,
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: 1,
};

export const defaultTheme = createMuiTheme({
  // Color palette
  palette: {
    objective: objectivePalette,
    primary: {
      main: objectivePalette.blue.main,
    },
    error: {
      main: objectivePalette.red.main,
    },
    text: {
      primary: objectivePalette.dark.night,
      secondary: objectivePalette.dark.neutral,
    },
    background: {
      default: objectivePalette.light.white,
      paper: objectivePalette.light.white,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768, // iPad portrait
      md: 1024, // iPad landscape
      lg: 1280, // laptop (mackbook)
      xl: 1440, // desktop
      xxl: 1600, // desktop
      xxxl: 1920, // desktop (design)
    },
  },
  // Spacing - based on ODL factor4
  spacing: (factor) => `${factor * 4}px`,
  // Typography
  typography: {
    // Font family - based on ODL
    fontFamily: standardFontFamily,
    // Normalize typography - separate styles from semantic
    h1: standardTypography,
    h2: standardTypography,
    h3: standardTypography,
    h4: standardTypography,
    h5: standardTypography,
    h6: standardTypography,
    subtitle1: standardTypography,
    subtitle2: standardTypography,
    body1: standardTypography,
    body2: standardTypography,
    htmlFontSize: 14,
  },
  overrides: {
    // Normalize typography - separate styles from semantic
    MuiCssBaseline: {
      "@global": {
        h1: {
          margin: 0,
        },
        h2: {
          margin: 0,
        },
        h3: {
          margin: 0,
        },
        h4: {
          margin: 0,
        },
        h5: {
          margin: 0,
        },
        h6: {
          margin: 0,
        },
        p: {
          margin: 0,
        },
        "@font-face": [
          {
            fontFamily: "Objective",
            fontStyle: "normal",
            fontDisplay: "block",
            fontWeight: "normal",
            src: `
    url(${ObjectiveWOFF2}) format('woff2'),
    url(${ObjectiveWOFF}) format('woff')
  `,
          },
          {
            fontFamily: "Noto Sans",
            fontStyle: "normal",
            fontDisplay: "block",
            fontWeight: 400,
            src: `url("${NotoSansRegular}") format('truetype')`,
          },
          {
            fontFamily: "Noto Sans",
            fontStyle: "normal",
            fontDisplay: "block",
            fontWeight: 500,
            src: `url("${NotoSansMedium}") format('truetype')`,
          },
          {
            fontFamily: "Noto Sans",
            fontStyle: "normal",
            fontDisplay: "block",
            fontWeight: 600,
            src: `url("${NotoSansSemiBold}") format('truetype')`,
          },
          {
            fontFamily: "Noto Sans",
            fontStyle: "normal",
            fontDisplay: "block",
            fontWeight: 700,
            src: `url("${NotoSansBold}") format('truetype')`,
          },
        ],
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: objectivePalette.red.main,
      },
    },
    MuiInputLabel: {
      formControl: {
        position: "relative",
        transform: "unset",
        paddingBottom: 16,
        fontWeight: 500,
      },
    },
    MuiLink: {
      root: {
        cursor: "pointer",
        fontWeight: 500,
      },
    },
  },
});
