import React, { useState } from "react";
import { AppBar, Drawer, List, ListItem, ListItemIcon, ListItemText, ThemeProvider, Toolbar } from "@material-ui/core";
import styled, { css, ThemeProvider as StyledThemeProvider } from "styled-components/macro";
import CssBaseline from "@material-ui/core/CssBaseline";
import { defaultTheme } from "styles/defaultTheme";
import EditAttributesIcon from "@material-ui/icons/EditAttributes";
import FormioFormEditor from "components/FormioFormEditor";
import Authentication from "components/Authentication";
import Guard from "components/Guard";
import { apiClient } from "../services/api-client/ApiClient";

export const AuthenticationContext = React.createContext({
  authenticated: false,
  setAuthenticated: (authenticated: boolean) => {},
});

const App: React.FC = () => {
  const [authenticated, setAuthenticated] = useState(false);

  React.useEffect(() => {
    const token = apiClient.getAuthToken();
    // TODO: detect auth token expiry / valid
    setAuthenticated(Boolean(token));
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <StyledThemeProvider theme={defaultTheme}>
        <CssBaseline />

        <StyledAppBar position="fixed">
          <Toolbar>Objective Build Admin UI</Toolbar>
        </StyledAppBar>

        <StyledDrawer variant="permanent">
          <Toolbar />

          <StyledDrawerContentContainer>
            <List>
              <ListItem button selected={true}>
                <ListItemIcon>
                  <EditAttributesIcon />
                </ListItemIcon>
                <ListItemText primary={"FormIO Editor"} />
              </ListItem>
            </List>
          </StyledDrawerContentContainer>
        </StyledDrawer>

        <StyledMainContent>
          <AuthenticationContext.Provider value={{ authenticated, setAuthenticated }}>
            <Guard condition={authenticated} fallback={<Authentication />}>
              <FormioFormEditor />
            </Guard>
          </AuthenticationContext.Provider>
        </StyledMainContent>
      </StyledThemeProvider>
    </ThemeProvider>
  );
};

const StyledAppBar = styled(AppBar)(
  ({ theme }) => css`
    z-index: ${theme.zIndex.drawer + 1};
  `
);

const StyledDrawer = styled(Drawer)(
  ({ theme }) => css`
    z-index: ${theme.zIndex.drawer};
  `
);

const StyledDrawerContentContainer = styled.div(
  ({ theme }) => css`
    width: 240px;
    overflow: auto;
  `
);

const StyledMainContent = styled.main(
  ({ theme }) => css`
    margin-left: 240px;
    margin-top: 64px;
    padding: 24px;
  `
);

export default App;
